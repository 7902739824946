import * as React from 'react';
import { connect } from 'react-redux';

import {
  Button,
  createStyles,
  Divider,
  Fade,
  Grid,
  Theme,
  Typography,
  WithStyles,
  withStyles
} from '@material-ui/core';
import router from 'next/router';
import { default as Lottie } from 'react-lottie';

import classnames from 'classnames';

import * as UserActions from '@actions/UserActions';
import graphAnimationJson from '@assets/lottie/graph-animation.json';
import locationAnimationJson from '@assets/lottie/location-animation.json';
import { default as backgroundResized } from '@assets/mahkeo-DXTRyjAeA5E-unsplash.jpg?resize&sizes[]=400&sizes[]=600&sizes[]=800&sizes[]=1000&sizes[]=1200&sizes[]=1600&sizes[]=2000';

import Hero from '@components/Hero';
import Loading from '@components/Loading';
import Search from '@components/Search';

import { IStore, IUserState } from '@reducers';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      overflowX: 'hidden',
      position: 'relative'
    },
    landingPaper: {
      backgroundColor: theme.palette.primary.main,
      padding: theme.spacing(5),
      marginTop: theme.spacing(4),
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
      maxWidth: 400,
      color: 'white',
      flexGrow: 1,
      [theme.breakpoints.between('sm', 'md')]: {
        marginTop: theme.spacing(14),
        marginLeft: theme.spacing(10)
      },
      [theme.breakpoints.up('md')]: {
        marginTop: theme.spacing(14),
        marginLeft: theme.spacing(18)
      }
    },

    landingContainer: {
      height: '100%'
    },
    button: {
      textAlign: 'right',
      marginTop: theme.spacing(3)
    },
    detailContent: {
      width: '100%',
      [theme.breakpoints.down('xs')]: {
        marginTop: theme.spacing(8)
      },

      maxWidth: theme.breakpoints.values.md,
      marginLeft: 'auto',
      marginRight: 'auto',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    },
    detail: {
      textAlign: 'center',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    },
    mainSubtitleDetail: {
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: 700
    },
    viewSampleButton: {
      width: '100%',
      textAlign: 'center',
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(6)
    },
    detailSubtitle: {
      maxWidth: theme.breakpoints.values.sm,
      marginLeft: 'auto',
      marginRight: 'auto'
    },
    finalCallToAction: {
      color: 'white',
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(26),
      position: 'relative'
    },
    finalCallToActionBackground: {
      width: '100%',
      top: 0,
      left: 0,
      zIndex: -1,
      position: 'absolute',
      right: 0,
      bottom: 0,
      height: '100%',
      objectFit: 'cover'
    },
    finalCallToActionGrid: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    },
    secondDetailTitle: {
      marginTop: theme.spacing(26),
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: theme.breakpoints.values.sm
    },
    freeTrialButton: {
      textAlign: 'center'
    }
  });

const mapDispatchToProps = {
  toggleAuthModal: UserActions.toggleAuthModal
};

type IProps = WithStyles<typeof styles> &
  IUserState &
  typeof mapDispatchToProps & { pageContext: { isMobile: boolean } };

const HomePage: React.FC<IProps> = ({
  classes,
  toggleAuthModal,
  pageContext: { isMobile },
  user,
  isFetching
}) => {
  React.useEffect(() => {
    if (user) {
      router.push('/search');
    }
  });

  const defaultOptions = {
    loop: true,
    autoplay: true
  };

  const signUp = () =>
    toggleAuthModal({
      showModal: 'signup'
    });

  return (
    <>
      <Hero isMobile={isMobile} isExpandable />
      {isFetching || user ? (
        <Loading />
      ) : (
        <>
          <div className={classes.landingContainer}>
            <Fade in>
              <Search />
            </Fade>
          </div>
          <div className={classes.root}>
            <div className={classes.detailContent}>
              <Typography variant="h3" align="center" gutterBottom>
                {'Lieuu Neighborhoods'}
              </Typography>
              <Typography
                className={classes.mainSubtitleDetail}
                variant="h5"
                align="center"
                gutterBottom
              >
                {
                  "We'll help you explore a neighborhood with extensive data so you can decide if it is right for you and your family."
                }
              </Typography>
              <div className={classes.viewSampleButton}>
                <Button
                  onClick={signUp}
                  variant="contained"
                  color="secondary"
                  size="large"
                >
                  {'Sign up'}
                </Button>
              </div>

              <Divider />

              <Grid
                className={classes.detail}
                container
                spacing={8}
                alignItems="center"
              >
                <Grid item xs={12} md={6}>
                  <Typography gutterBottom variant="h4" color="inherit">
                    {'Accurate reports'}
                  </Typography>

                  <Typography
                    className={classes.detailSubtitle}
                    variant="h6"
                    color="inherit"
                  >
                    {
                      "You shouldn't have to be a professional investor to get access to important data you need to make an informed decision about a neighborhood."
                    }
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Lottie
                    options={{
                      ...defaultOptions,
                      animationData: graphAnimationJson
                    }}
                    height={350}
                    width="100%"
                  />
                </Grid>
              </Grid>

              <Divider />

              <Grid
                className={classnames(classes.detail)}
                container
                spacing={8}
                alignItems="center"
              >
                <Grid item xs={12} md={6}>
                  <Lottie
                    options={{
                      ...defaultOptions,
                      animationData: locationAnimationJson
                    }}
                    height={350}
                    width="100%"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography gutterBottom variant="h4" color="inherit">
                    Find the best location
                  </Typography>

                  <Typography
                    className={classes.detailSubtitle}
                    variant="h6"
                    color="inherit"
                  >
                    Search with a map-driven interface to find the perfect
                    neighborhood for your safety, short-term rental, and
                    community needs.
                  </Typography>
                </Grid>
              </Grid>
            </div>

            <div className={classes.finalCallToAction}>
              <img
                alt="Call to Action Background"
                className={classes.finalCallToActionBackground}
                srcSet={backgroundResized.srcSet}
                src={backgroundResized.src}
              />

              <Grid
                className={classes.finalCallToActionGrid}
                container
                spacing={4}
                justify="center"
                alignItems="center"
              >
                <Grid item xs={12}>
                  <Typography
                    className={classnames(
                      classes.detail,
                      classes.secondDetailTitle
                    )}
                    variant="h4"
                    align="center"
                  >
                    Discover important information on housing trends, rentals,
                    crime, and demographics.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <div className={classes.freeTrialButton}>
                    <Button
                      onClick={signUp}
                      variant="contained"
                      color="secondary"
                      size="large"
                    >
                      Sign Up Today
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default withStyles(styles)(
  connect(
    (state: IStore): IUserState => state.user,
    mapDispatchToProps
  )(HomePage)
);
